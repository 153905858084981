import React from 'react';
import './contact.css';

const GetInTouch = ({title,underline}) => {
  return (
    <div>
        {title && <h2 className={`cs-widget_title_get ${underline ? 'underline' : ''}`}>{title}</h2>}
        <li style={{listStyle:'none',color:"white"}}>
          <span className='cs-accent_color_get'></span>
           email: info@syraenergy.com
         </li>
         <li style={{listStyle:'none', color:"white"}}>
           <span className='cs-accent_color'></span>
           Phone: +91 80881 95597
         </li>

    </div>
  )
}

export default GetInTouch